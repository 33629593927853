<div class="alert_main" *ngIf="bool_alert" (click)="fn_close()">
    <div class="alert" >
        <span class="closebtn" (click)="fn_close()">&times;</span> 
        {{let_alert_msg}}
    </div>
</div>

<div class="row banner_header ">
    <div class="col-md-8">
        <h2 class="m-0-btm"> Registration Form - Grading Fee Refund</h2>
    </div>
    <div class="col-md-4" style="text-align: right;">
        <button class="m-0-btm btn-success cus_btn " routerLink="/trackId"> <i class="fa fa-arrow-right"></i> Track your Ticket </button>
    </div>
  </div>


  <div class="container download_section pad_btm_size">

  
    <div class="row">
        <div class="col-md-12">
            <div *ngIf="!bool_pri_result">
                <!-- main app container -->
                <div class="card m-3">
  
                    <!-- {{bdnuForm.value | json}} -->
                    <div class="card-body" >
                      <!-- <h2>SINGLE / BULK</h2> -->
                        <form [formGroup]="bdnuForm" (ngSubmit)="onSubmit()">
  
  
  
                            <div class="form-row">
  
                              <div class="form-group col-4">
                                <label>State <span class="clr_rd"> <span class="cls_red">*</span></span></label>
                                <select formControlName="state" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
                                    (change)="onChangeState($event)">
                                    <option [selected]="true" value="" [disabled]="true">Select State</option>
                                    <option *ngFor="let country of state" [value]="country.state">
                                        {{ country.state }}
                                    </option>
                                </select>
  
                                <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                    <div *ngIf="f.state.errors.required">State is required</div>
                                </div>
                            </div>
  
                            <div class="form-group col-4">
                              <label>District <span class="clr_rd"> <span class="cls_red">*</span></span></label>
  
  
  
                              <select  formControlName="district" class="form-control"
                                  [ngClass]="{ 'is-invalid': submitted && f.district.errors }"
                                  (change)="onChangeDist($event)">
                                  <option [selected]="true" value="" [disabled]="true">Select District</option>
                                  <option *ngFor="let country of district" [value]="country.district">
                                        {{ country.district }}
                                  </option>
                              </select>
                              <div *ngIf="submitted && f.district.errors" class="invalid-feedback">
                                  <div *ngIf="f.district.errors.required">District is required</div>
                              </div>
                          </div>
  
  
  
                              <div class="form-group col-4">
                                <label> ITI Name  <span class="cls_red">*</span></label>
  
                                <select [(ngModel)]="itiname_let" formControlName="itiname" class="form-control"
                                  [ngClass]="{ 'is-invalid': submitted && f.itiname.errors }"
                                  (change)="onChangeItiName($event)">
                                  <option [selected]="true" [value]="tests" [disabled]="true">Select ITI Name</option>
                                  <option *ngFor="let country of itiname" [value]="country.itiname">
                                      {{ country.iticode }} - {{ country.itiname }} 
                                  </option>
                              </select>
  
                              <input type="text" hidden [(ngModel)]="iticode_let" formControlName="miscode" >
  
  
                              <div *ngIf="submitted && f.itiname.errors" class="invalid-feedback">
                                  <div *ngIf="f.itiname.errors.required">ITI Name is required</div>
                              </div>
  
  
                            </div>
  
                            <!-- <div class="form-group col-4">
                              <label> MIS Code</label>
  
                              <select  formControlName="miscode" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.miscode.errors }"
                             >
                              <option [selected]="true" value="" [disabled]="true">Select ITI Name</option>
                              <option *ngFor="let country of itimiscode" [value]="country.iticode">
                                  {{ country.iticode }}
                              </option>
                          </select>
  
  
                              <div *ngIf="submitted && f.miscode.errors" class="invalid-feedback">
                                  <div *ngIf="f.miscode.errors.required">Enter Your MIS Code</div>
                              </div>
                          </div> -->
  
                          </div>
  
                          <div class="form-row">
  
                            <div class="form-group col-12">
                              <h3>Provide Your Bank Information for refund</h3>
                              </div>
  
  
  
                          <div class="form-group col-4">
                            <label> Account Number <span class="cls_red">*</span></label>
                            <input type="text" formControlName="accno" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.accno.errors }"
                                placeholder="Enter Your Account Number" maxlength="25" minlength="5" (keypress)="omit_number($event)" />
                            <div *ngIf="submitted && f.accno.errors" class="invalid-feedback">
                                <div *ngIf="f.accno.errors.required">Enter Your Account Number</div>
                                <div *ngIf="f.accno.errors.minlength">Minimum Length 5 character</div>
                            </div>
                        </div>
  
                        <div class="form-group col-4">
                          <label> Bank Name <span class="cls_red">*</span></label>
                          <input type="text" formControlName="bankname" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.bankname.errors }"
                              placeholder="Enter Your Bank Name" maxlength="50" (keypress)="omit_text($event)"  />
                          <div *ngIf="submitted && f.bankname.errors" class="invalid-feedback">
                              <div *ngIf="f.bankname.errors.required">Enter Your Bank Name</div>
                              <div *ngIf="f.bankname.errors.minlength">Minimum Length 3 character</div>
                          </div>
                      </div>
  
                      <div class="form-group col-4">
                        <label> Branch Name <span class="cls_red">*</span></label>
                        <input type="text" formControlName="branchname" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.branchname.errors }"
                            placeholder="Enter Your Branch Name" maxlength="50" (keypress)="omit_spl($event)"  />
                        <div *ngIf="submitted && f.branchname.errors" class="invalid-feedback">
                            <div *ngIf="f.branchname.errors.required">Enter Your Branch Name</div>
                            <div *ngIf="f.branchname.errors.minlength">Minimum Length 3 character</div>
                        </div>
                    </div>
  
                      <div class="form-group col-4">
                        <label> IFSC Code <span class="cls_red">*</span></label>
                        <input type="text" formControlName="ifsccode" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.ifsccode.errors }"
                            placeholder="Enter Your IFSC Code" maxlength="11" (keypress)="omit_text_number($event)" />
                        <div *ngIf="submitted && f.ifsccode.errors" class="invalid-feedback">
                            <div *ngIf="f.ifsccode.errors.required">Enter Your IFSC Code</div>
                            <div *ngIf="f.ifsccode.errors.minlength">Enter Your Valid IFSC Code</div>
                        </div>
                    </div>
  
  
  
                                <div class="form-group col-4">
                                    <label>Name of Contact Person <span class="cls_red">*</span></label>
                                    <input type="text" formControlName="name" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                        placeholder="Enter Your Name" maxlength="50" (keypress)="omit_text($event)" />
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Enter Your Name</div>
                                        <div *ngIf="f.name.errors.minlength">Minimum Length 3 character</div>
                                    </div>
                                </div>
  
                                <div class="form-group col-4">
                                    <label>Email Id of Contact Person <span class="cls_red">*</span></label>
                                    <input type="text" formControlName="email" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" maxlength="50"
                                        placeholder="Enter Your Email Address" (keypress)="omit_email($event)" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Enter Your Email Id</div>
                                        <div *ngIf="f.email.errors.pattern">
                                            Enter Your Valid Email Id 
                                        </div>
  
                                    </div>
                                </div>
  
                                <div class="form-group col-4">
                                    <label>Mobile Number of Contact Person <span class="cls_red">*</span></label>
                                    <input type="text" formControlName="mobileno" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.mobileno.errors }" maxlength="10"
                                        placeholder="Enter Your Mobile Number" (keypress)="omit_number($event)" />
                                    <div *ngIf="submitted && f.mobileno.errors" class="invalid-feedback">
                                        <div *ngIf="f.mobileno.errors.required">
                                            Enter Your Mobile Number
                                        </div>
                                        <div *ngIf="f.mobileno.errors.pattern">
                                            Enter Your Valid Mobile Number
                                        </div>
                                    </div>
  
                                    <!-- <div *ngIf="submitted && f.mobileno.errors" class="invalid-feedback">
                              <div *ngIf="f.mobileno.errors.max"> Mobile no must 10 digit</div>
                          </div> -->
                                </div>
  
                                <div class="form-group col-4">
                                  <label> Grading Fee Amount Deposited <span class="cls_red">*</span></label>
                                  <input type="text" formControlName="deposited" class="form-control"
                                      [ngClass]="{ 'is-invalid': submitted && f.deposited.errors }" maxlength="4"
                                      placeholder="Enter Your Amount Deposited" (keypress)="omit_number($event)" />
                                  <div *ngIf="submitted && f.deposited.errors" class="invalid-feedback">
                                      <div *ngIf="f.deposited.errors.required">Enter Your Amount Deposited</div>
                                      <div *ngIf="f.deposited.errors.minlength">Minimum Length 4 character</div>
                                  </div>
                              </div>
  
                              <div class="form-group col-4">
                                <label> Grading Fee paid date <span class="cls_red">*</span></label>
                                  
                                  <div class="cus_date">
                                      <input matInput [matDatepicker]="dp" class="form-control" [attr.disabled]='true' placeholder="Enter Your Grading Fee paid date" [min]="minDate" [max]="maxDate" formControlName="paydate" [formControl]="matdate" (keypress)="stopkey($event)" readonly>
                                      <mat-datepicker-toggle matSuffix [for]="dp" class="toggle_cls"></mat-datepicker-toggle>
                                      <mat-datepicker #dp></mat-datepicker>
                                  </div>
                                  
  
                                <!-- <input matInput [matDatepicker]="picker">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker> -->
  
  
                                <!-- <input type="text" formControlName="paydate"  [dpDayPicker]="datePickerConfig" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.paydate.errors }" maxlength="50"
                                    placeholder="Enter Your Grading Fee paid date"  /> -->
                                <div *ngIf="submitted && f.paydate.errors" class="invalid-feedback">
                                    <div *ngIf="f.paydate.errors.required">Enter Your Grading Fee paid date</div>
                                </div>
                            </div>
  
  
                                <div class="form-group col-4">
                                    <label>UTR / CC Avenue Reference Number <span class="cls_red">*</span></label>
                                    <input type="text" formControlName="ccrefno" class="form-control"  maxlength="12"
                                        placeholder="Enter CC Avenue Ref Number" (keypress)="omit_number($event)" (change)="utr_dup_check($event)"
                                        />
                                    <div *ngIf="submitted && f.ccrefno.errors" class="invalid-feedback">
                                        <div *ngIf="f.ccrefno.errors.required">
                                            Enter Your CC Reference number
                                        </div>
                                        <div *ngIf="f.ccrefno.errors.pattern">
                                          Enter Your Valid CC Refernce number
                                      </div>
                                    </div>
 
                                </div>
                                <!-- <div class="form-group col-4">
                                    <label>Enter Passbook Frontpage/ Cancel Check Leaf<span class="cls_red">*</span></label>
                                    <input type="file" formControlName="ccrefno" class="form-control" required><span class="cls_blue">(Only .jpg file upload(10kb-100kb))</span>
                                    
                                </div> -->

  
  
                                <div class="form-group col-12 form-check">
                                    <input type="checkbox" formControlName="acceptTerms" id="acceptTerms"
                                        class="form-check-input" [ngClass]="{
                        'is-invalid': submitted && f.acceptTerms.errors
                      }" />
                                    <label for="acceptTerms" class="form-check-label">I accept the Terms and condition</label>
                                    <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
                                    </div>
                                    <p>1. The Account number and the CC Avenue reference number updated by me is correct to the best of my knowledge.</p>
                                    <p>2. NIMI will not be responsible for wrong Account details provoided.</p>
                                    <p>3. Amount will be transfered after verification of records from NIMI.</p>
                                    <p>4. NIMI will retain the processing charge of Rs.550 and only the rest of the grading fee paid will be refunded.</p>
                                </div>
  
  
  
                                <div class="form-group col-12">
                                    <div class="text-center">
                                        <button class="btn btn-primary mr-1">Submit</button>
                                        <button class="btn btn-secondary" type="reset" (click)="onReset()">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
  
  
  
                </div>
            </div>
  
  
            <div class="eriformr_res_cls" *ngIf="bool_pri_result">
                <p>Your Complaint has been Sucessfully Registered. </p>
                <p>Your Ticket ID is <b> {{pri_genrateid}} </b> </p>
                <p>An Confirmation Email has been sent to your Registered Email Id </p>
  
                <!-- <p>To Raise Exam Related Issue Click Here <button class="track_btn_cls" routerLink="/crs"
                        routerLinkActive="active"> Exam
                        Related
                        Issue </button> </p> -->
            </div>
  
  
        </div>
    </div>
  
  
  
  
  </div>
  



  <!--HElp Desk startz-->

  <div class="whatsapp_chat_support wcs_fixed_right wcs-show" id="example_1" *ngIf="whatsapp_bool">
    <!-- <div class="wcs_button_label">
       Post Your recruitment Related queries
     </div> -->
    <div class="wcs_button wcs_button_circle">
      <span class="fa fa-phone"></span>
    </div>

    <div class="wcs_popup">
      <div class="wcs_popup_close">
        <i class="fa fa-window-close" (click)="show_need()" >
            
        </i>
      </div>
      <div class="wcs_popup_header">
        <strong style="color: #fff;">Need Help? </strong>
        <br>
        <div class="wcs_popup_header_description"> Time: 10 AM to 5 PM (Mon-Fri)</div>
      </div>
      <div class="wcs_popup_person_container">
       
        <div class="wcs_popup_person" aria-disabled="true" >
          <div class="wcs_popup_person_content">
            <div class="wcs_popup_person_name"> <i class="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:nimigrading@gmail.com"> nimigrading@gmail.com </a>  </div>
          </div>
        </div>            
        <div class="wcs_popup_person" aria-disabled="true" >
          <div class="wcs_popup_person_content">
            <div class="wcs_popup_person_name"> <i class="fa fa-phone" aria-hidden="true"></i> <a href="tel:+919498087085"> 94980 87085 </a>   </div>
          </div>
        </div>
        <div class="wcs_popup_person" aria-disabled="true" >
          <div class="wcs_popup_person_content">
            <div class="wcs_popup_person_name"> <i class="fa fa-phone" aria-hidden="true"></i> <a href="tel:+919498069086"> 94980 69086 </a>   </div>
          </div>
        </div>
     

      </div>
    </div>
  </div>

  <div  (click)="show_main()" class="whatsapp_chat_support wcs_fixed_right" id="example_2" *ngIf="need_bool">
    <div class="wcs_button">

      <i class="fa fa-phone" aria-hidden="true"></i>
       Help Desk
   </div>
   </div>
