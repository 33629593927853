
<h2> Pending List</h2>

<mat-form-field>
    <input matInput (change)="applyFilter($event)" placeholder="Filter">
</mat-form-field>
<!-- <a class="btn btn-primary" href="https://nimionlineadmission.in/dgt/downloaddatadirnimixls.php">Download</a> -->
<a class="btn btn-primary" href="https://nimionlineadmission.in/dgt/download_gred_xls.php">Download</a>


<div class="example-container mat-elevation-z8">

        <mat-table  [dataSource]="dataSource" #sort="matSort" matSort>


        <ng-container matColumnDef="ticketId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Id </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.refno}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="iticode">
            <mat-header-cell *matHeaderCellDef mat-sort-header> State </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.state}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="singlerollno">
            <mat-header-cell *matHeaderCellDef mat-sort-header> District
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.district}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> ITI Name </mat-header-cell>
            <mat-cell *matCellDef="let element">  {{element.miscode}} -  {{element.itiname}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mobileno">
            <mat-header-cell *matHeaderCellDef mat-sort-header> CC Ref No </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ccrefno}} </mat-cell>

        </ng-container>

        <ng-container matColumnDef="ccrefno">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ccrefnostatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Mobile No </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.mobileno}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="paydate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Payment Date </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.paydate}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="entrydate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Entry Date </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.enter_date}} </mat-cell>
        </ng-container>

   
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header> View </mat-header-cell>
            <mat-cell *matCellDef="let row"> <button class="btn btn-success"
                    (click)="selected_family_member.select(row)" (click)="report()">View</button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    
    </mat-table>
    
  

<mat-paginator #paginator="matPaginator"  [length]="totallength_one" (page)="pageEvent_one = $event; pageChanged_one($event)" [pageSizeOptions]="[ 5,10, 25, 50, 100, 500]"></mat-paginator>






</div>

<hr>


<h2> Refund / Verified / Rejected List</h2>

<mat-form-field>
    <input matInput (change)="applyFilter_two($event)" placeholder="Filter">
</mat-form-field>
<a class="btn btn-primary" href="https://nimionlineadmission.in/dgt/download_gred_xls.php">Download</a>


<div class="example-container mat-elevation-z8">
    <mat-table  [dataSource]="dataSource_two" #t1sort="matSort" matSort>

        <ng-container matColumnDef="ticketId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Ticket Id </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.refno}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="iticode">
            <mat-header-cell *matHeaderCellDef mat-sort-header> State </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.state}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="singlerollno">
            <mat-header-cell *matHeaderCellDef mat-sort-header> District
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.district}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> ITI Name </mat-header-cell>
            <mat-cell *matCellDef="let element">  {{element.miscode}} -  {{element.itiname}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mobileno">
            <mat-header-cell *matHeaderCellDef mat-sort-header> CC Ref No </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ccrefno}} </mat-cell>

        </ng-container>

        <ng-container matColumnDef="ccrefno">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ccrefnostatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Mobile No </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.mobileno}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="paydate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Payment Date </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.paydate}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="entrydate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Entry Date </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.enter_date}} </mat-cell>
        </ng-container>

   
        <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header> View </mat-header-cell>
            <mat-cell *matCellDef="let row"> <button class="btn btn-success"
                    (click)="selected_family_member.select(row)" (click)="report_two()">View</button>
            </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="submit">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Submit </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button class="btn btn-success"  (click)="selected_family_member.select(row)" (click)="report_submit()">Submit</button>
            </mat-cell>
        </ng-container> -->

        <mat-header-row *matHeaderRowDef="displayedColumns_two"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns_two;"></mat-row>

    </mat-table>
    

    <mat-paginator #t1paginator="matPaginator"  [length]="totallength_two" (page)="pageEvent_two = $event; pageChanged_two($event)" [pageSizeOptions]="[ 5,10, 25, 50, 100, 500]"></mat-paginator>


</div>

