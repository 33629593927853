import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.checkLogin();
  }

  logout() {
    window.localStorage.removeItem("logged_in");
    this.router.navigate(['/login']);
  }

  checkLogin() {
    if (window.localStorage.getItem('logged_in') == 'undefined') {
      this.logout();
    }
  }
}
