import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DatePipe } from '@angular/common';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],

})
export class FormComponent implements OnInit {

  @ViewChild('uploadPhotoInput', { static: false }) uploadPhotoInput!: ElementRef;


  form1: boolean = true;
  public bool_pri_result: boolean = false;

  bdnuForm: any;
  nimiForm: any;
  submitted = false;
  bool_disable:boolean = true;

  bool_alert:boolean =false;
  let_alert_msg:string = '';

  public numberStud: any;

  public niminumberStud: any;


  public state: any;
  public district: any;
  public iticode: any;
  public trade: any;


  public pri_genrateid: any;
  public ccrefnoStatus: any;
  public var_placeholder = " Enter Order ID P16_PU09001617-1617099122"
  public enter_date: any;
  public iticode_let:any = '';
  public itiname_let:any = '';
  public tests:any = '';

  //convenience getter for easy access to form fields
  get f() {
    return this.bdnuForm.controls;
  }




  onSubmit() {
    //alert(d.getDate() + "/" + month + "/" + d.getFullYear())
    //alert("test: " + this.ccrefnoStatus);
    this.bdnuForm.controls.miscode.setValue(this.iticode_let);
    console.log(this.iticode_let)

    this.utr_dup_check;

    this.submitted = true;

    // stop here if form is invalid
    console.log(this.bdnuForm.invalid);
    if (this.bdnuForm.invalid) {
      return;
    }

    // console.log(this.datePipe.transform(this.bdnuForm.controls['paydate'].value, 'dd-MM-yyyy'));

    
    this.bdnuForm.controls.paydate.setValue(this.datePipe.transform(this.bdnuForm.controls['paydate'].value, 'dd-MM-yyyy'));

    console.log(JSON.stringify(this.bdnuForm.value))

    // console.log(this.datePipe.transform(this.matdate.value, 'dd-MM-yyyy'))

    const d = new Date();
    var month = d.getMonth() + 1;

    this.enter_date = d.getDate() + "/" + month + "/" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

    this.bdnuForm.controls.entryDate.setValue(this.enter_date);


    //throw("dfsdfsdf");


    this.api
      .insertRfg(this.bdnuForm.value)
      .subscribe((result: any) => {
        console.log("bdnu: " + JSON.stringify(result));
        this.pri_genrateid = result.refid;
        this.form1 = false;
        this.bool_pri_result = true;
        window.scroll(0, 0);

      });
  }



  public bool_photo:boolean =false;





  constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router, private datePipe: DatePipe) { }


  ngOnInit(): void {

    this.api.liststate().subscribe((result: any) => {
      console.log(result)
      this.state = result;
    });




    // this.api.listIticode().subscribe((result: any) => {
    //   console.log(JSON.stringify(result));
    //   this.iticode = result;
    // });

    this.bdnuForm = this.formBuilder.group({
      state: ['', Validators.required],
      district: ['', Validators.required],
      itiname: ['', Validators.required],
      miscode: [''],
      accno: ['', [Validators.required, Validators.minLength(5)]],
      bankname: ['', [Validators.required, Validators.minLength(3)]],
      branchname: ['', [Validators.required, Validators.minLength(3)]],
      ifsccode: ['', [Validators.required, Validators.minLength(11)]],
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobileno: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      deposited: ['',  [Validators.required, Validators.minLength(4)]],
      paydate: ['',  Validators.required],
      ccrefno: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{12}$")]],
      entryDate: [''],
      acceptTerms: [false, Validators.requiredTrue],


    });



  }












  ////////////











  public date = new Date().getDate();
  public month = new Date().getMonth() + 1;
  public year = new Date().getFullYear();
  public currentDate = this.date + '-' + this.month + '-' + this.year;

  datePickerConfig = {
    format: 'DD-MM-YYYY',
    min: '01-12-2010',
    max: '31-03-2011'
    //max: this.currentDate,
  };


  matdate = new FormControl(moment());
  minDate = new Date(2019, 0, 1);
  maxDate = new Date(2019, 11, 31);


  onReset() {
    this.submitted = false;
    this.bdnuForm.reset();
  }


  omit_number(event: any) {
    var k;
    k = event.charCode;
    return ((k >= 48 && k <= 57) || k == 32);
    // return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  omit_email(event: any) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || k == 45 || k == 46 || k == 64 || k == 95 || k == 32);
  }

  omit_text(event: any) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 32);
    // return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  omit_spl(event: any) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || k == 47 || k == 32);
    // return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  omit_text_number(event: any) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || k == 32);
    // return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  
  omit_all(event: any) {
    var k;
    k = event.charCode;
    return ((k >= 127 && k <= 0) || k==8); 
    // return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  stopkey(event: any): void{
    event.preventDefault();
  }

  cc_check(event: any) {

    var rdbtn_value = this.bdnuForm.controls['radiobtn'].value;
    if(rdbtn_value == 'radio2'){


      var value = event.target.value;
      var args = {
        ccref: value
      }

      this.api.ccrefStatus(value).subscribe((data: any) => {
        this.ccrefnoStatus = data.RefStatus;
        if(this.ccrefnoStatus == ''){
          //alert('empty')
          this.ccrefnoStatus = 'blank'
        }
        this.bdnuForm.controls.ccrefnostatus.setValue(this.ccrefnoStatus);

      });

      // this.api.ccCheck(args).subscribe((result: any) => {


      //   if (result[0]['count'] > 0) {
      //     alert("Already Enter this CC Refno ")
      //     this.bdnuForm.controls['ccrefno'].reset()

      //   }
      //   else {
      //     this.api.ccrefStatus(value).subscribe((data: any) => {
      //       this.ccrefnoStatus = data.RefStatus;
      //       if(this.ccrefnoStatus == ''){
      //         //alert('empty')
      //         this.ccrefnoStatus = 'blank'
      //       }
      //       this.bdnuForm.controls.ccrefnostatus.setValue(this.ccrefnoStatus);

      //     });
      //   }
      // });

    }
    else{

      var value = event.target.value;
      var args = {
        ccref: value
      }


          this.api.ccrefStatus(value).subscribe((data: any) => {
            this.ccrefnoStatus = data.RefStatus;
            if(this.ccrefnoStatus == ''){
              //alert('empty')
              this.ccrefnoStatus = 'blank'
            }
            this.bdnuForm.controls.ccrefnostatus.setValue(this.ccrefnoStatus);

          });


    }






  }

  public bool_iti_select: boolean = true;
  public bool_iti_text: boolean = false;

  entry_iti(event:any){
    if(event.target.value == 'Enter ITI Code'){
      // alert("test")
      this.bool_iti_select = false;
      this.bool_iti_text = true;

      this.bdnuForm.controls['iticode'].reset();
      return;
    }
  }



  onChangeState(data: any) {

    var myargs = {
      stateName: data.target.value
    }
    this.api.enterState(myargs).subscribe((result: any) => {
      this.district = result;
    });
  }

public itiname:any;
public itimiscode:any;

  onChangeDist(data: any) {

    this.bdnuForm.controls['itiname'].reset();
    var myargs = {
      districtName: data.target.value
    }
    this.api.enterDistrict(myargs).subscribe((result: any) => {

    
      this.itiname_let = '';
      this.itiname = result;
      this.iticode_let = '';
    });

  }

  onChangeItiName(data: any) {
    var myargs = {
      itinameName: data.target.value
    }
    this.api.enterItiCode(myargs).subscribe((result: any) => {

      console.log(result);
      this.itimiscode = result;
      this.iticode_let = result[0]['iticode'];
    });

  }

  utr_dup_check(event:any){
   // console.log(this.bdnuForm.controls['miscode'].value)
  //  this.submitted = true;

  //   if (this.bdnuForm.invalid) {
  //     this.bdnuForm.controls['ccrefno'].reset();
  //     return;
  //   }

    var args = {
      miscode: this.bdnuForm.controls['miscode'].value,
      ccrefno: event.target.value

    }

    this.api.utr_dup_check(args).subscribe((result: any) => {
      if(result[0]['Count'] == 0){      

        // console.log(this.iticode_let)

        // this.submitted = true;

        // if (this.bdnuForm.invalid) {
        //   this.bdnuForm.controls['ccrefno'].reset();
        //   this.bool_disable = true;
        //   return;
        // }

        // this.bool_disable = false;

      }
      else{
       // alert("Grievance already raised for the cc avenue reference number");
        this.bool_alert = true;
        this.let_alert_msg = 'Grievance already raised for the cc avenue reference number';

          this.bdnuForm.controls['ccrefno'].reset();

          // this.submitted = true;


          //   if (this.bdnuForm.invalid) {
          //     this.bool_disable = true;
          //     return;
          //   }

        
      }
    });

  } 


  fn_close(){
    this.bool_alert = false;
  }

  
  whatsapp_bool:boolean = false;
  need_bool:boolean = true
  show_main(){
    this.need_bool = false;
  this.whatsapp_bool = true;
  }
  show_need(){
    this.need_bool = true;
    this.whatsapp_bool = false;
  }
  
  
}