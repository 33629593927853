import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ApiService } from '../../api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormactionService } from '../../api/formaction.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from "@angular/cdk/collections";
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild('sort') sort!: MatSort;

  @ViewChild('t1paginator') t1paginator!: MatPaginator;
  @ViewChild('t1sort') t1sort!: MatSort;

  public query: string = '';
  public page: number = 1;
  public offset: number = 5;
  public pageEvent_one: any;
  totallength_one:any = 1 ;

  public query_two: string = '';
  public page_two: number = 1;
  public offset_two: number = 5;
  public pageEvent_two: any;
  totallength_two:any = 1 ;

  displayedColumns = ['ticketId', 'iticode', 'singlerollno', 'name', 'mobileno', 'ccrefno', 'ccrefnostatus', 'paydate', 'entrydate', 'action'];
  SRC_SELECTED_FAMILYMEMBER: any[] = [];
  DATA_SELECTED_FAMILYMEMBER = Object.assign(this.SRC_SELECTED_FAMILYMEMBER);
  dataSource = new MatTableDataSource<any>(this.DATA_SELECTED_FAMILYMEMBER);
  selected_family_member = new SelectionModel<any>(true, []);

  displayedColumns_two = ['ticketId', 'iticode', 'singlerollno', 'name', 'mobileno', 'ccrefno', 'ccrefnostatus', 'paydate', 'entrydate', 'action'];
  SRC_SELECTED_FAMILYMEMBER_TWO: any[] = [];
  DATA_SELECTED_FAMILYMEMBER_TWO = Object.assign(this.SRC_SELECTED_FAMILYMEMBER_TWO);
  dataSource_two = new MatTableDataSource<any>(this.DATA_SELECTED_FAMILYMEMBER_TWO);
  selected_family_member_two = new SelectionModel<any>(true, []);





  constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router, private formaction: FormactionService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

    this.dataLoader();
    this.dataLoader_two();
  }

  dataLoader(){

    this.api.dirnimiList(this.query, this.page, this.offset).subscribe((result: any) => {
    
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      //this.totallength = result[0]['count'];

      this.api.dirnimiList_count(this.query, this.page, this.offset).subscribe((result: any) => {
        //console.log(result[0]['count'])
        
        this.totallength_one = result[0]['count'];
        console.log(this.totallength_one)
      });


    })
  }

  pageChanged_one(event: any) {

    
    this.page = parseInt(event.pageIndex) + 1;
    this.offset = parseInt(event.pageSize);
    // this.totallength = 5000;
    // this.dataLoader();
console.log(this.page + " , " + this.offset)
    this.api.dirnimiList(this.query, this.page, this.offset).subscribe((result: any) => {
      this.dataSource = new MatTableDataSource(result);

      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;

    })

    
  }

  applyFilter(filterValue: any) {
    filterValue = filterValue.target.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches

    this.api.dirnimiList(filterValue, this.page, this.offset).subscribe((result: any) => {
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      //this.totallength = result[0]['count'];

      this.api.dirnimiList_count(filterValue, this.page, this.offset).subscribe((result: any) => {
  
        this.totallength_one = result[0]['count'];
      });

      
    })

   // this.dataSource_view.filter = filterValue;

    
  }


  report() {
    this.selected_family_member.selected.forEach((item) => {
      var msg = JSON.stringify(item);
      var parsedarray = JSON.parse(msg);
      var args = {
        ticketId: parsedarray['refno'],
        iticode: parsedarray['miscode'],
        ccrefno: parsedarray['ccrefno'],
      };
      this.formaction.set_dirnimiList_single(args);
      this.router.navigateByUrl('/dashboard/view')

    });
    this.selected_family_member.clear();
  }


    /*Second */

    dataLoader_two(){

      this.api.dirnimiVerifiedList(this.query_two, this.page_two, this.offset_two).subscribe((result: any) => {
      
        this.dataSource_two = new MatTableDataSource(result);
        this.dataSource_two.paginator = this.t1paginator;
        this.dataSource_two.sort = this.t1sort;
        //this.totallength = result[0]['count'];
  
        console.log(result)
        this.api.dirnimiVerifiedList_count(this.query_two, this.page_two, this.offset_two).subscribe((result: any) => {
          //console.log(result[0]['count'])
          
          this.totallength_two = result[0]['count'];
          console.log(this.totallength_two)
        });
  
  
      })
    }

    pageChanged_two(event: any) {

    
      this.page_two = parseInt(event.pageIndex) + 1;
      this.offset_two = parseInt(event.pageSize);
      // this.totallength = 5000;
      // this.dataLoader();
      console.log(this.page_two + " , " + this.offset_two)
      this.api.dirnimiVerifiedList(this.query_two, this.page_two, this.offset_two).subscribe((result: any) => {
        this.dataSource_two = new MatTableDataSource(result);
      })      
    }


  
    
    applyFilter_two(filterValue: any) {
      filterValue = filterValue.target.value.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  
      this.api.dirnimiVerifiedList(filterValue, this.page_two, this.offset_two).subscribe((result: any) => {
        this.dataSource_two = new MatTableDataSource(result);
        this.dataSource_two.paginator = this.t1paginator;
        this.dataSource_two.sort = this.t1sort;
        //this.totallength = result[0]['count'];
  
        this.api.dirnimiVerifiedList_count(filterValue, this.page_two, this.offset_two).subscribe((result: any) => {
    
          this.totallength_two = result[0]['count'];
        });
  
        
      })
  
     // this.dataSource_view.filter = filterValue;
  
      
    }

    report_two() {
      this.selected_family_member.selected.forEach((item) => {
        var msg = JSON.stringify(item);
        var parsedarray = JSON.parse(msg);
        var args = {
          ticketId: parsedarray['refno'],
          iticode: parsedarray['miscode'],
          ccrefno: parsedarray['ccrefno'],
          view: 'report'
        };
        this.formaction.set_dirnimiList_single(args);
        this.router.navigateByUrl('/dashboard/view')
  
      });
      this.selected_family_member.clear();
    }
  




}


