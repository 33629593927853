import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ApiService } from '../../api/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-count',
  templateUrl: './count.component.html',
  styleUrls: ['./count.component.css']
})
export class CountComponent implements OnInit {

  public total:any = '';
  public total_pending:any = '';
  public total_refund:any = '';
  public total_verified:any = '';
  public total_rejected:any = '';


  constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.api.total_count().subscribe((result: any) => {
      console.log(result[0]['count'])
      this.total = result[0]['count'];
    });

    this.api.total_pending_count().subscribe((result: any) => {
      console.log(result[0]['count'])
      this.total_pending = result[0]['count'];
    });

    this.api.total_refunded_count().subscribe((result: any) => {
      console.log(result[0]['count'])
      this.total_refund = result[0]['count'];
    });

    this.api.total_verified_count().subscribe((result: any) => {
      console.log(result[0]['count'])
      this.total_verified = result[0]['count'];
    });

    this.api.total_rejected_count().subscribe((result: any) => {
      console.log(result[0]['count'])
      this.total_rejected = result[0]['count'];
    })

  }

}
