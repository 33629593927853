
<div class="banner_header">
    <div class="col-md-12">
      <h2 style="margin-bottom: 0px;">Dashboard</h2>
    </div>
  </div>
  
  <div class="d-flex" id="wrapper" style="min-height: 500px;">
    <!-- Sidebar-->
    <div class="bg-light border-right col-md-2" id="sidebar-wrapper">
      <div class="list-group list-group-flush">
        <a class="list-group-item list-group-item-action bg-light" routerLink="/dashboard/count">Dashboard</a>
        <!-- <a class="list-group-item list-group-item-action bg-light" routerLink="/dashboard/eriedit"
          routerLinkActive="active"> Edit </a> -->
        <!-- <a class="list-group-item list-group-item-action bg-light" routerLink="/dashboard/erilist"
          routerLinkActive="active"> Exam Related Issue </a> -->
        <a class="list-group-item list-group-item-action bg-light" routerLink="/dashboard/list"
          routerLinkActive="active"> List </a>
<!--   
          <a class="list-group-item list-group-item-action bg-light" routerLink="/dashboard/dirnimilist"
          routerLinkActive="active"> Direct NIMI Payment Related Issue </a>
  
          <a class="list-group-item list-group-item-action bg-light" routerLink="/dashboard/adminreport"
          routerLinkActive="active"> Report </a> -->
        <!-- <a class="list-group-item list-group-item-action bg-light" routerLink="/dashboard/crilist"
          routerLinkActive="active"> Candidate Related Issue </a> -->
  
        <!-- <a class="list-group-item list-group-item-action bg-light" routerLink="/dashboard/eristatus"
          routerLinkActive="active"> Status </a> -->
        <a class="list-group-item list-group-item-action bg-light" (click)="logout()"> Logout </a>
      </div>
    </div>
    <!-- Page Content-->
    <div class="col-md-10" id="page-content-wrapper" style="width: 100%">
      <!-- <div class="logout_cls">
        <button class="btn btn-primary" (click)="logout()"><i class="fa fa-lock"></i> Logout</button>
      </div> -->
      <router-outlet></router-outlet>
    </div>
  </div>

  
