import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ApiService } from '../../api/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  adminForm: any;
  submitted = false;

  bool_alert:boolean =false;
  let_alert_msg:string = '';
  public stroge_val = window.localStorage.getItem("logged_in");
  constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router) { }

  ngOnInit(): void {

    
      if(this.stroge_val){
        this.router.navigateByUrl('/dashboard/count');
      }

      
      
        

    this.adminForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }


  get admin() {
    return this.adminForm.controls;
  }


  onSubmitAdmin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.adminForm.invalid) {
      return;
    }


    this.api.adminLogin(this.adminForm.value).subscribe((result: any) => {
      console.log(result);
      if (result[0]['count'] == '0') {
        this.bool_alert = true;
        this.let_alert_msg = 'Login with valid Credentials';

        //alert("Login with valid Credentials")
        return;
      }
      else {
        window.localStorage.setItem("logged_in", "TRUE");
        this.router.navigateByUrl('/dashboard/count');
      }
    });
  }

  fn_close(){
    this.bool_alert = false;
  }
    
  omit_spl(event: any) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || k == 47 || k == 32);
    // return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

 
}
