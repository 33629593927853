<div class="alert_main" *ngIf="bool_alert" (click)="fn_close()">
  <div class="alert" >
      <span class="closebtn" (click)="fn_close()">&times;</span> 
      {{let_alert_msg}}
  </div>
</div>

<div class="banner_header">
    <div class="col-md-12">
      <h2 style="margin-bottom: 0px;">Admin</h2>
    </div>
  </div>
  
  <div class="container ">
  
  
    <div class="row cus_row">
      <div class="col-md-4 instruc_right">
  
        <!--Admin From Start-->
        <div>
          <div class="card-body">
            <h2>Login</h2>
            <form [formGroup]="adminForm" (ngSubmit)="onSubmitAdmin()">
              <div class="form-row">
                <div class="form-group col-12">
                  <label>User Name</label>
                  <input type="text" formControlName="username" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && admin.username.errors }" (keypress)="omit_spl($event)"
                    maxlength="20" />
                  <div *ngIf="submitted && admin.username.errors" class="invalid-feedback">
                    <div *ngIf="admin.username.errors.required">username is required</div>
                  </div>
                </div>
                <div class="form-group col-12">
                  <label>Password</label>
                  <input type="text" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && admin.password.errors }" (keypress)="omit_spl($event)"
                    maxlength="12" />
                  <div *ngIf="submitted && admin.password.errors" class="invalid-feedback">
                    <div *ngIf="admin.password.errors.required">password is required</div>
                  </div>
  
                </div>
                <div class="form-group col-12">
                  <div class="text-center">
                    <button class="btn btn-primary mr-1">Login</button>
                    <!-- <button type="button" (click)="onLoginReset()" class="btn btn-primary mr-1">Login</button> -->
                  </div>
                </div>
  
  
              </div>
            </form>
          </div>
        </div>
        <!--Admin Form End-->
      </div>
  
  
    </div>
  </div>
  
  
  <style>
    .banner_header {
      background: #2955a9;
      padding: 10px 0px;
      text-align: left;
      color: #fff;
      margin: 0px;
    }
  
    .banner_header h2 {
      font-size: 21px;
    }
  </style>