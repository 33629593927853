import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { ApiService } from '../../api/api.service';
import { FormactionService } from '../../api/formaction.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {

  public ticketId: any = '';
  public entrydate: any = '';
  public state: any = '';
  public district: any = '';
  public itiname: any = '';
  public iticode: any = '';
  public accno: any = '';
  public bankname: any = '';
  public branchname: any = '';
  public ifsccode: any = '';
  public trade: any = '';
  public name: any = '';
  public email: any = '';
  public mobileno: any = '';
  public ccrefno: any = '';
  public status: any = '';
  public deposited: any = '';
  public paydate: any = '';

  
  public CCAvenueRef: any = '';
  public OrderNo: any = '';
  public OrderDatetime: any = '';
  public ShipDateTime: any = '';
  public GrossAmount: any = '';
  public OrderAmount: any = '';
  public BillName: any = '';
  public BillCity: any = '';
  public BillState: any = '';
  public BillZip: any = '';
  public BillTel: any = '';
  public BillEmail: any = '';
  public OrderStatus: any = '';
  public OrderBankResponse: any = '';
  public order_capt_amt: any = '';
  public RefundAmount: any = '';

  bdnuForm: any;
  submitted = false;

  public bool_form:boolean = true;

  get f() {
    return this.bdnuForm.controls;
  }

  constructor(private formBuilder: FormBuilder, private api: ApiService, private formaction: FormactionService, private router: Router) { }

  ngOnInit(): void {

    this.formaction.get_dirnimiList_single().subscribe((result: any) => {

      
      var args = {
        ticketId: result.ticketId,
        iticode: result.iticode,
        ccrefno: result.ccrefno
      }

        if(args.ticketId == undefined){
          this.router.navigate(['/dashboard/list']);
        }

        if(result.view == 'report'){
          this.bool_form = false;
        }
      this.api.trackIdview(args).subscribe((result: any) => {
        console.log(result)
        this.ticketId = result.refno;
        this.entrydate = result.entrydate;
        this.state = result.state;
        this.district = result.district;
        this.itiname = result.itiname;
        this.iticode = result.miscode;
        this.accno = result.accno;
        this.bankname = result.bankname;
        this.branchname = result.branchname;
        this.ifsccode = result.ifsccode;
        this.trade = result.trade;
        this.name = result.name;
        this.email = result.email;
        this.mobileno = result.mobileno;
        this.ccrefno = result.ccrefno;
        this.deposited = result.deposited;
        this.paydate = result.paydate;
        this.status = result.gri_status;

      })

      this.api.trackIdrefund(args).subscribe((result: any) => {
        this.CCAvenueRef = result.CCAvenueRef;
        this.OrderNo = result.OrderNo;
        this.OrderDatetime = result.OrderDatetime;
        this.ShipDateTime = result.ShipDateTime;
        this.GrossAmount = result.GrossAmount;
        this.OrderAmount = result.OrderAmount;
        this.BillName = result.BillName;
        this.BillCity = result.BillCity;
        this.BillState = result.BillState;
        this.BillZip = result.BillZip;
        this.BillTel = result.BillTel;
        this.BillEmail = result.BillEmail;
        this.OrderStatus = result.OrderStatus;
        this.OrderBankResponse = result.OrderBankResponse;
        this.order_capt_amt = result.order_capt_amt;
        this.RefundAmount = result.RefundAmount;
        
      });

      // this.api.trackIdPduBulkregno(args).subscribe((result: any) => {
      //   this.bulkregno = result;
      // });

      // this.api.trackIdPduBulkregno(args).subscribe((result: any) => {
      //   console.log(result);
      //   let bulkreg = '';
      //   result.forEach((element: any) => {
      //     bulkreg = bulkreg + element['regno'] + ',';
      //   });
      //   this.bulkregno = bulkreg.slice(0, -1);
      // });

    });

    this.bdnuForm = this.formBuilder.group({
      refno: [''],    
      email: [''],
      itiname: [''],
      gri_status: ['', Validators.required],
      gri_remarks: [''],
    });

  }

  onSubmit() {


    this.submitted = true;

    this.bdnuForm.controls['refno'].setValue(this.ticketId);
    this.bdnuForm.controls['email'].setValue(this.email);
    this.bdnuForm.controls['itiname'].setValue(this.itiname);

    if (this.bdnuForm.invalid) {
      return;
    }

    console.log(JSON.stringify(this.bdnuForm.value))


    this.api.updateRfg(this.bdnuForm.value).subscribe((result: any) => {

      this.router.navigate(['/dashboard/list']);

        // console.log("bdnu: " + JSON.stringify(result));
        // this.pri_genrateid = result.refid;
        // this.form1 = false;
        // this.bool_pri_result = true;
        // window.scroll(0, 0);
      });
      
  }

}
