<div class="alert_main" *ngIf="bool_alert" (click)="fn_close()">
    <div class="alert" >
        <span class="closebtn" (click)="fn_close()">&times;</span> 
        {{let_alert_msg}}
    </div>
</div>


<div class="banner_header">
    <div class="col-md-12">
      <h2 style="margin-bottom: 0px;">Track Your Complaint</h2>
    </div>
  </div>
  
  <div class="container ">
  
  
    <div class="row cus_row" *ngIf="bool_show" >
      <div class="col-md-4 instruc_right">
  
        <!--Admin From Start-->
        <div>
          <div class="card-body">
            <form [formGroup]="reactiveForm" (ngSubmit)="onSubmit()">
              <div class="form-row">
                <div class="form-group col-12">
                  <label>Enter your Ticket ID</label>
                  <input type="text" formControlName="ticketId" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && valid.ticketId.errors }" (keypress)="omit_spl($event)"
                    maxlength="20" />
                  <div *ngIf="submitted && valid.ticketId.errors" class="invalid-feedback">
                    <div *ngIf="valid.ticketId.errors.required">username is required</div>
                  </div>
                </div>
      
                <div class="form-group col-12">
                  <div class="text-center">
                    <button class="btn btn-primary mr-1">Submit</button>
                    <!-- <button type="button" (click)="onLoginReset()" class="btn btn-primary mr-1">Login</button> -->
                  </div>
                </div>
  
  
              </div>
            </form>
          </div>
        </div>
        <!--Admin Form End-->
      </div>
  
  
    </div>

    <div class="row cus_row" *ngIf="!bool_show" >

                
                <div [class]="let_bg_clr" class=" status_tag" style="text-align: left;">
                    <!--table-->
              
                    <table>
                        <tr>
                            <th>Ticket Id</th>
                            <td> {{this.ticketId}} </td>
                        </tr>
                        <tr>
                            <th>State</th>
                            <td>{{this.state}} </td>
                        </tr>
                        <tr>
                            <th>District</th>
                            <td>{{this.district}} </td>
                        </tr>
                        <tr>
                            <th>ITI Name</th>
                            <td>{{this.itiname}} </td>
                        </tr>
                        <tr>
                            <th>ITI Code</th>
                            <td>{{this.iticode}} </td>
                        </tr>
                        <tr>
                            <th>Account Number</th>
                            <td>{{this.accno}} </td>
                        </tr>
                        <tr>
                            <th>Bank Name</th>
                            <td>{{this.bankname}} </td>
                        </tr>
                        <tr>
                            <th>Branch Name</th>
                            <td>{{this.branchname}} </td>
                        </tr>
                        <tr>
                            <th>IFSC Code</th>
                            <td>{{this.ifsccode}} </td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{{this.name}} </td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{{this.email}} </td>
                        </tr>
                        <tr>
                            <th>Mobile No</th>
                            <td>{{this.mobileno}} </td>
                        </tr>
                        <tr>
                            <th>Deposited</th>
                            <td>{{this.deposited}} </td>
                        </tr>
                        <tr>
                            <th>Pay Date</th>
                            <td>{{this.paydate}} </td>
                        </tr>                      
                        <tr>
                            <th>CC Ref Number</th>
                            <td>{{this.ccrefno}} </td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>{{this.status}} </td>
                        </tr>

                        
                        


                        <!-- <tr>
                    <th>trade</th>
                    <td>{{this.trade}} </td>
                </tr> -->


                      



                    </table>

                </div>


    
      </div>
  </div>
  
  
  <style>
    .banner_header {
      background: #2955a9;
      padding: 10px 0px;
      text-align: left;
      color: #fff;
      margin: 0px;
    }
  
    .banner_header h2 {
      font-size: 21px;
    }
  </style>