import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './api/auth.guard';

import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormComponent } from './pages/form/form.component';
import { CountComponent } from './pages/count/count.component';
import { ListComponent } from './pages/list/list.component';
import { ViewComponent } from './pages/view/view.component';
import { TrackComponent } from './pages/track/track.component';

const routes: Routes = [
  {
    path: '', component: FormComponent, children: [
      { path: '', component: FormComponent }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'trackId', component: TrackComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], children: [
    { path: 'count', component: CountComponent },
    { path: 'list', component: ListComponent },
    { path: 'view', component: ViewComponent },
  ]
},


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
