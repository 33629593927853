import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token = "Some token";

  constructor(private http: HttpClient) { }

  public bool_login: boolean = false;
  public bool_invigi_login: boolean = false;
  public bool_candidate_login: boolean = false;


  gettoken() {

    if (localStorage.getItem("logged_in") == "TRUE") {
      this.bool_login = true;
    } else {
      this.bool_login = false;
    }
    return this.bool_login;
  }

  gettoken_invigilator() {

    if (localStorage.getItem("invigilator_logged_in") == "TRUE") {
      this.bool_invigi_login = true;
    } else {
      this.bool_invigi_login = false;
    }
    return this.bool_invigi_login;
  }

  gettoken_candidate() {

    if (localStorage.getItem("candidate_logged") == "TRUE") {
      this.bool_candidate_login = true;
    } else {
      this.bool_candidate_login = false;
    }
    return this.bool_candidate_login;
  }
}
