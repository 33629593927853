import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ApiService } from '../../api/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.css']
})
export class TrackComponent implements OnInit {

  reactiveForm: any;
  submitted = false;

  let_bg_clr:any='';
  bool_show:boolean = true;
  bool_alert:boolean =false;
  let_alert_msg:string = '';

  public ticketId: any = '';
  public entrydate: any = '';
  public state: any = '';
  public district: any = '';
  public itiname: any = '';
  public iticode: any = '';
  public accno: any = '';
  public bankname: any = '';
  public branchname: any = '';
  public ifsccode: any = '';
  public trade: any = '';
  public name: any = '';
  public email: any = '';
  public mobileno: any = '';
  public ccrefno: any = '';
  public status: any = '';
  public deposited: any = '';
  public paydate: any = '';

  constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.reactiveForm = this.formBuilder.group({
      ticketId: ['', [Validators.required]],
    });
  }


  get valid() {
    return this.reactiveForm.controls;
  }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.reactiveForm.invalid) {
      return;
    }




    this.api.trackId(this.reactiveForm.value).subscribe((result: any) => {

      if(result.status == "failss"){
        this.let_alert_msg = 'Enter your Valid Ticket ID';
        this.bool_alert = true;
        this.bool_show = true;
      }
      else{
        this.bool_show = false;

        this.ticketId = result.refno;
      this.entrydate = result.entrydate;
      this.state = result.state;
      this.district = result.district;
      this.itiname = result.itiname;
      this.iticode = result.miscode;
      this.accno = result.accno;
      this.bankname = result.bankname;
      this.branchname = result.branchname;
      this.ifsccode = result.ifsccode;
      this.trade = result.trade;
      this.name = result.name;
      this.email = result.email;
      this.mobileno = result.mobileno;
      this.ccrefno = result.ccrefno;
      this.deposited = result.deposited;
      this.paydate = result.paydate;
      this.status = result.gri_status;

      if(this.status == 'pending'){
        
        this.let_bg_clr = 'pend';
      }
      else if(this.status == 'Refunded'){
        this.let_bg_clr = 'refnd';
      }
      else{
        this.let_bg_clr = 'rejt';
      }

      }
      
      

    })

  }

  fn_close(){
    this.bool_alert = false;
  }
  omit_spl(event: any) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || k == 47 || k == 32);
    // return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
