<div class="row">
    <div class="col-md-12">
        <h2> Total  </h2>
    </div>

    <div class="col-md-3">
        <div class="card">
            <div class="container">
                <h4><b>Total</b></h4>
                <p>{{this.total}}</p>
            </div>
        </div>
    </div>

    <div class="col-md-3">
        <div class="card">
            <div class="container">
                <h4><b>Pending</b></h4>
                <p>{{this.total_pending}}</p>
            </div>
        </div>
    </div>

    <div class="col-md-3">
        <div class="card">
            <div class="container">
                <h4><b>Refunded</b></h4>
                <p>{{this.total_refund}}</p>
            </div>
        </div>
    </div>


    <div class="col-md-3">
        <div class="card">
            <div class="container">
                <h4><b>Verified</b></h4>
                <p>{{this.total_verified}}</p>
            </div>
        </div>
    </div>


    <div class="col-md-3">
        <div class="card">
            <div class="container">
                <h4><b>Rejected</b></h4>
                <p>{{this.total_rejected}}</p>
            </div>
        </div>
    </div>


    
</div>
<hr>