import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, throwError } from "rxjs";
import { map, retry, catchError } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  //  public SERVER_BASEURL = "http://localhost:5001/";
  public SERVER_BASEURL = "https://gradenodetest.nimiquestionbank.in/";

  public ccref_url = "https://nimionlineadmission.in/AITTCBT-FEES22/Check_CCAvenue.php?ccav=";
  public ccrefupdate_url =  "https://www.nimionlineadmission.in/AITTCBT-FEES22/Check_CCAvenuefull.php?ccav=";

  // public cc_mob = 'https://sms.nettyfish.com/vendorsms/pushsms.aspx?apikey=9acf13b0-566f-47f9-b206-fe62af6f48da&clientId=a3b6b0ab-e393-44d4-aab0-1a3b69501f70&msisdn=9597151452&sid=NIMIAP&msg=Your%20OTP%20is%20'+'11111'+'%20for%20reference%20number%20'+22222+'%20-%20NIMI&fl=0&gwid=2';

  // public cc_mob = 'https://sms.nettyfish.com/vendorsms/pushsms.aspx?apikey=9acf13b0-566f-47f9-b206-fe62af6f48da&clientId=a3b6b0ab-e393-44d4-aab0-1a3b69501f70&msisdn=9597151452&sid=NIMIAP&msg=Your OTP Number is '+9999+' for reference number '+'NAN123456'+'- NIMI&fl=0&gwid=2';



  constructor(private httpClient: HttpClient) { }

  liststate() {
    return this.httpClient.get(this.SERVER_BASEURL + "liststate/").pipe(
      map((data) => {
        //alert(data);
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  enterState(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + 'enterState/', args).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    )
  }

  enterDistrict(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + 'enterDistrict/', args).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    )
  }

  enterItiCode(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + 'enterItiCode/', args).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    )
  }

  utr_check(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + 'utr_check/', args).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    )
  }

  utr_dup_check(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + 'utr_dup_check/', args).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    )
  }

  adminLogin(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + "adminLogin/", args).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  total_count() {
    return this.httpClient.get(this.SERVER_BASEURL + "total_count/").pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  total_pending_count() {
    return this.httpClient.get(this.SERVER_BASEURL + "total_pending_count/").pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  total_refunded_count() {
    return this.httpClient.get(this.SERVER_BASEURL + "total_refunded_count/").pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  total_verified_count() {
    return this.httpClient.get(this.SERVER_BASEURL + "total_verified_count/").pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  total_rejected_count() {
    return this.httpClient.get(this.SERVER_BASEURL + "total_rejected_count/").pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  dirnimiList(query: string, page: number, offset: number){
    return this.httpClient.get(this.SERVER_BASEURL + "dirnimiList?q=" + query + "&page=" + page + "&offset=" + offset).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }
  dirnimiList_count(query: string, page: number, offset: number) {
    return this.httpClient.get(this.SERVER_BASEURL + "dirnimiList_count?q=" + query + "&page=" + page + "&offset=" + offset).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  dirnimiVerifiedList(query_two: string, page_two: number, offset_two: number){
    return this.httpClient.get(this.SERVER_BASEURL + "dirnimiVerifiedList?q=" + query_two + "&page=" + page_two + "&offset=" + offset_two).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }
  dirnimiVerifiedList_count(query_two: string, page_two: number, offset_two: number) {
    return this.httpClient.get(this.SERVER_BASEURL + "dirnimiVerifiedList_count?q=" + query_two + "&page=" + page_two + "&offset=" + offset_two).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  trackIdview(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + "trackIdview/", args).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  trackIdrefund(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + "trackIdrefund/", args).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  updateRfg(args: any) {

    return this.httpClient.post(this.SERVER_BASEURL + "updateRfg/", args).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }



  insertRfg(args: any) {

    return this.httpClient.post(this.SERVER_BASEURL + "insertRfg/", args).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  listIticode() {
    return this.httpClient.get(this.SERVER_BASEURL + "listIticode/").pipe(
      map((data) => {
        //alert(data);
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  ccrefStatus(args: any) {
    return this.httpClient.get(this.ccref_url + args).pipe(
      map((data) => {
        //alert(data);
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  ccCheck(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + "ccCheck/", args).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  trackIdPduBulkregno(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + "trackIdPduBulkregno/", args).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  trackId(args: any) {
    return this.httpClient.post(this.SERVER_BASEURL + "trackId/", args).pipe(
      map((data) => {

        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `${error.error.message}`;
    } else {
      // server-side error
      //errorMessage = `${error.status} <br /> Message: ${error.message}`;
      errorMessage = `Server Error<br /> Message: ${error.message}`;
    }

    return throwError(errorMessage);
  }


  public get() {
    return this.httpClient.get<any>(this.SERVER_BASEURL);
  }

}
