import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, throwError } from "rxjs";
import { map, retry, catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FormactionService {

  constructor() { }

  public service_pdu_data = new BehaviorSubject<any>('');

  set_pduList_single(myArgs: any) {
    this.service_pdu_data.next(myArgs);
  }
  get_pduList_single() {
    return this.service_pdu_data.asObservable();
  }

  public service_dirnimi_data = new BehaviorSubject<any>('');

  set_dirnimiList_single(myArgs: any) {
    this.service_dirnimi_data.next(myArgs);
  }
  get_dirnimiList_single() {
    return this.service_dirnimi_data.asObservable();
  }

  


}
