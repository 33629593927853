<div class="container download_section pad_btm_size">

    <div>
        <div class="card m-3">
            <h5 class="card-header" style="font-size: 21px; color: #fff; background-color: #2955a9;">
                View
            </h5>
            <div class="card-body" style="padding-top: 0px;">
                <div class="row">

                    <div class="col-md-6 status_tag" style="text-align: left;">
                        <!--table-->
                        <table>
                            <tr>
                                <th>CC Ref Number</th>
                                <td>{{this.ccrefno}} </td>
                            </tr>
                            <tr>
                                <th>ITI Code</th>
                                <td>{{this.iticode}} </td>
                            </tr>
                            <tr>
                                <th>Pay Date</th>
                                <td>{{this.paydate}} </td>
                            </tr>    
                            <tr>
                                <th>Ticket Id</th>
                                <td> {{this.ticketId}} </td>
                            </tr>
                            <tr>
                                <th>Deposited</th>
                                <td>{{this.deposited}} </td>
                            </tr>  
                            <tr>
                                <th>Name</th>
                                <td>{{this.name}} </td>
                            </tr>
                            <tr>
                                <th>ITI Name</th>
                                <td>{{this.itiname}} </td>
                            </tr>   
                            <tr>
                                <th>District</th>
                                <td>{{this.district}} </td>
                            </tr>
                            <tr>
                                <th>State</th>
                                <td>{{this.state}} </td>
                            </tr>      
                            <tr>
                                <th>Email</th>
                                <td>{{this.email}} </td>
                            </tr>
                            <tr>
                                <th>Mobile No</th>
                                <td>{{this.mobileno}} </td>
                            </tr>                                                           
                            <tr>
                                <th>Account Number</th>
                                <td>{{this.accno}} </td>
                            </tr>
                            <tr>
                                <th>Bank Name</th>
                                <td>{{this.bankname}} </td>
                            </tr>
                            <tr>
                                <th>Branch Name</th>
                                <td>{{this.branchname}} </td>
                            </tr>
                            <tr>
                                <th>IFSC Code</th>
                                <td>{{this.ifsccode}} </td>
                            </tr>                                                                                                    
                            <tr>
                                <th>Status</th>
                                <td>{{this.status}} </td>
                            </tr>
   
                            
                            


                            <!-- <tr>
                        <th>trade</th>
                        <td>{{this.trade}} </td>
                    </tr> -->


                          



                        </table>
                    </div>

                    <div class="col-md-6 status_tag" style="text-align: left;">
                        <!--table-->
                        <table>
                            <tr>
                                <th>CCAvenueRef</th>
                                <td> {{this.CCAvenueRef}} </td>
                            </tr>
                            <tr>
                                <th>OrderNo</th>
                                <td>{{this.OrderNo}} </td>
                            </tr>
                            <tr>
                                <th>OrderDatetime</th>
                                <td>{{this.OrderDatetime}} </td>
                            </tr>
                            <tr>
                                <th>ShipDateTime</th>
                                <td>{{this.ShipDateTime}} </td>
                            </tr>
                            <tr>
                                <th>GrossAmount</th>
                                <td>{{this.GrossAmount}} </td>
                            </tr>
                            <tr>
                                <th>OrderAmount</th>
                                <td>{{this.OrderAmount}} </td>
                            </tr>
                            <tr>
                                <th>BillName</th>
                                <td>{{this.BillName}} </td>
                            </tr>
                            <tr>
                                <th>BillCity</th>
                                <td>{{this.BillCity}} </td>
                            </tr>
                            <tr>
                                <th>BillState</th>
                                <td>{{this.BillState}} </td>
                            </tr>
                            <tr>
                                <th>BillEmail</th>
                                <td>{{this.BillEmail}} </td>
                            </tr>
                            <tr>
                                <th>BillTel</th>
                                <td>{{this.BillTel}} </td>
                            </tr>             
                            <tr>
                                <th>BillZip</th>
                                <td>{{this.BillZip}} </td>
                            </tr>                            
                            <tr>
                                <th>OrderStatus</th>
                                <td>{{this.OrderStatus}} </td>
                            </tr>
                            <tr>
                                <th>OrderBankResponse</th>
                                <td>{{this.OrderBankResponse}} </td>
                            </tr>
                            <tr>
                                <th>order_capt_amt</th>
                                <td>{{this.order_capt_amt}} </td>
                            </tr>                      
                            <tr>
                                <th>RefundAmount</th>
                                <td>{{this.RefundAmount}} </td>
                            </tr>
                  
   
                            
                            


                            <!-- <tr>
                        <th>trade</th>
                        <td>{{this.trade}} </td>
                    </tr> -->


                          



                        </table>
                    </div>

                    <hr>


                </div>


                <div class="row" *ngIf='bool_form'>
                    <div class="col-md-12">
              
                        <div>
                            <!-- main app container -->
                            <div class="card m-3">
              
                                <!-- {{bdnuForm.value | json}} -->
                                <div class="card-body" >
                                  <!-- <h2>SINGLE / BULK</h2> -->
                                    <form [formGroup]="bdnuForm" (ngSubmit)="onSubmit()">
              
              
              
                                        <div class="form-row">
              
                                          <div class="form-group col-4">
                                            <label>Status <span class="clr_rd"> * </span></label>
                                            <select formControlName="gri_status" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.gri_status.errors }"
                                              >
                                                <option [selected]="true" value="" [disabled]="true">Select</option>
                                                <option  value="Refunded" >Refunded</option>
                                                <option  value="Verified" >Verified</option>
                                                <option  value="Rejected" >Rejected </option>
                                                
                                            </select>
              
                                            <div *ngIf="submitted && f.gri_status.errors" class="invalid-feedback">
                                                <div *ngIf="f.gri_status.errors.required">Status is required</div>
                                            </div>
                                        </div>

                                        <div class="form-group col-12">
                                            <label>Remarks</label>
                                            <textarea class="form-control" formControlName="gri_remarks"
                                                [ngClass]="{ 'is-invalid': submitted && f.gri_remarks.errors }"
                                                maxlength="500"></textarea>
                                            <div *ngIf="submitted && f.gri_remarks.errors" class="invalid-feedback">
                                                <div *ngIf="f.gri_remarks.errors.required">Remarks is required</div>
                                            </div>
                    
                                        </div>
              
              
                                      </div>
              
                                      
                                <div class="form-group col-12">
                                    <div class="text-center">
                                        <button class="btn btn-primary mr-1">Submit</button>
                             
                                    </div>
                                </div>

                      
                                    </form>
                                </div>
              
              
              
                            </div>
                        </div>

              
              
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<style>
    .banner_header {
        background: #2955a9;
        padding: 10px 0px;
        text-align: left;
        color: #fff;
        margin: 0px;
    }

    .banner_header h2 {
        font-size: 21px;
    }
</style>
